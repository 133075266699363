import React, { ReactNode } from 'react'

import QuotationMark from '../../assets/images/quotation_mark.inline.svg'
import './quote.sass'

interface Props {
  className?: string
  style?: React.CSSProperties
  quote: React.ReactNode
  quotee: ReactNode
}

const Quote: React.FC<Props> = ({ quote, quotee, className, style }) => {
  const cn = `quote ${className || ''}`
  const st = { ...(style || {}) }
  return (
    <figure className={cn} style={st}>
      <QuotationMark className="quote__marks" role="presentation" />
      {typeof quote === 'string' ? (
        <blockquote
          className="quote__quote"
          dangerouslySetInnerHTML={{ __html: quote }}
        />
      ) : (
        <blockquote className="quote__quote">{quote}</blockquote>
      )}
      <figcaption className="quote__quotee">{quotee}</figcaption>
    </figure>
  )
}

export default Quote
