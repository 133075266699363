import React, { useRef } from 'react'
import cx from 'classnames'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import BlurUp from '../blurUp/blurUp.js'
import HoverAnimation from '../hoverAnimation/hoverAnimation'
import Button from '../button/button.js'
import './horizontalCard.sass'

const HorizontalCard = ({
  animation,
  animationClassName,
  animationStyle,
  className,
  color,
  headline,
  link,
  linkLabel,
  logo,
  teaser,
}) => {
  const containerRef = useRef()

  return (
    <Link
      to={link}
      className={cx('horizontal-card', className)}
      role="link"
      tabIndex="0"
      ref={containerRef}
    >
      <div className="horizontal-card__text">
        <div className="horizontal-card__headline-container">
          <h3
            className="horizontal-card__headline"
            dangerouslySetInnerHTML={{ __html: headline }}
          />
        </div>
        <div
          className="horizontal-card__teaser"
          dangerouslySetInnerHTML={{ __html: teaser }}
        />
      </div>
      <div
        className={cx('horizontal-card__image', animationClassName)}
        style={{ backgroundColor: color }}
      >
        <HoverAnimation
          animation={animation}
          style={animationStyle}
          containerRef={containerRef}
        />
      </div>
      {logo && (
        <BlurUp
          role="presentation"
          className="horizontal-card__logo"
          src={logo}
        />
      )}

      <Button tag="div" tabIndex="-1" className="horizontal-card__button">
        <span dangerouslySetInnerHTML={{ __html: linkLabel }} />
      </Button>
    </Link>
  )
}

HorizontalCard.propTypes = {
  headline: PropTypes.string,
  teaser: PropTypes.string,
  animation: PropTypes.object,
  logo: PropTypes.string,
  link: PropTypes.string,
  linkLabel: PropTypes.string,
  animationStyle: PropTypes.object,
  color: PropTypes.string,
  animationClassName: PropTypes.string,
}

export default HorizontalCard
