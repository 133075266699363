import React, { useRef, useContext } from 'react'
import VisibilitySensor from 'react-visibility-sensor'
import cx from 'classnames'
import './banner.sass'
import Search from '../search/search.js'
import SearchResults from '../searchResults/searchResults'
import SearchFocusContainer from '../search/searchFocusContainer'
import { AppContext } from '../../state/appState'
import { SearchContext } from '../../state/search'

const Banner = props => {
  const bannerRef = useRef()
  const [, setState] = useContext(AppContext)
  const { query, setQuery, results, templatesFound } = useContext(SearchContext)

  const onSearchVisibilityChange = isVisible => {
    setState(s => ({ ...s, bannerSearchVisible: isVisible }))
    // TODO: refactor:
    if (typeof window !== 'undefined') {
      if (
        !isVisible &&
        document.activeElement &&
        document.activeElement.id === 'banner__search__input'
      ) {
        // a timeout is required because the input is still hidden
        setTimeout(() => {
          document.getElementById('header__search__input').focus({
            preventScroll: true,
          })
        })
      }
      if (
        isVisible &&
        document.activeElement &&
        document.activeElement.id === 'header__search__input'
      ) {
        document.getElementById('banner__search__input').focus({
          preventScroll: true,
        })
      }
    }
  }

  return (
    <div
      ref={bannerRef}
      style={{ top: `` }}
      id={props.id}
      className={cx('banner', props.className)}
    >
      <div className="banner__container">
        <header className="banner__header">
          <h1>Welcome to the UX Strategy Kit</h1>
          <p className="hide-to-s">
            Kickstart your ideation session or digital product development{' '}
            <br />
            sprint with this curated collection of activities and methods.
          </p>
        </header>
        <SearchFocusContainer>
          <VisibilitySensor
            partialVisibility
            onChange={onSearchVisibilityChange}
          >
            <Search
              id="banner__search"
              shallow
              query={query}
              setQuery={setQuery}
              hasResults={results && !!results.length}
              className="search--with-banner"
            />
          </VisibilitySensor>
          <SearchResults
            clear={() => setQuery('')}
            query={query}
            results={results}
            templatesFound={templatesFound}
          />
        </SearchFocusContainer>
      </div>
      <div className="banner__background">
        <div className="banner__pattern"></div>
      </div>
    </div>
  )
}

export default Banner
