import React from 'react'
import Quote from '../quote/quote'
import './testimonial.sass'

type Props = {
  children: React.ReactNode
  picture: string
  source: string
} & Pick<React.HTMLAttributes<HTMLElement>, 'className'>

const Testimonial: React.FC<Props> = ({
  children,
  className,
  picture,
  source,
}) => (
  <div className={`testimonial${className ? ` ${className}` : ''}`}>
    <Quote quote={children} quotee={source} />
    <img alt="Image of the author" src={picture} />
  </div>
)

export default Testimonial
