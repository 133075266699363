import React, { useEffect, createRef } from 'react'

import lottie from 'lottie-web'

const HoverAnimation = ({
  animation,
  autoplay = false,
  containerRef,
  loop = true,
  speed,
  style = {},
}) => {
  const animationContainer = createRef()

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg',
      loop,
      autoplay,
      animationData: animation,
    })
    const play = () => anim.play()
    const stop = () => anim.stop()
    if (speed !== undefined) {
      anim.setSpeed(speed)
    }
    anim.addEventListener('DOMLoaded', () => {
      const groupElements = anim.wrapper.querySelectorAll('svg g[clip-path]')
      groupElements.forEach(groupElement => {
        const path = groupElement.getAttribute('clip-path')
        groupElement.removeAttribute('clip-path')
        groupElement.setAttribute('path', path)
      })
    })
    if (!autoplay && containerRef && containerRef.current) {
      containerRef.current.addEventListener('mouseenter', play)
      containerRef.current.addEventListener('mouseleave', stop)
    }
    return () => {
      if (!autoplay && containerRef && containerRef.current) {
        containerRef.current.removeEventListener('mouseenter', play)
        containerRef.current.removeEventListener('mouseleave', stop)
      }
      anim.destroy()
    }
  }, [animation, animationContainer, autoplay, containerRef, loop, speed])

  return <div style={{ ...style }} ref={animationContainer} />
}

export default HoverAnimation
