import React from 'react'
import { graphql, PageProps } from 'gatsby'

import DefaultLayout from '../layouts/defaultLayout/defaultLayout'
import Banner from '../components/banner/banner'
import Button from '../components/button/button'
import HorizontalCard from '../components/horizontalCard/horizontalCard'
import HorizontalCardsGrid from '../components/horizontalCard/horizontalCardsGrid'
import Section from '../components/section/section'
import SectionHeadline from '../components/section/sectionHeadline'
import ToolTile from '../components/toolTile/toolTile'
import ToolTileGrid from '../components/toolTile/toolTileGrid'
import { mapMethod } from '../utils/mapMethodQueryResults'
import './index.sass'

import Arrow from '../assets/animations/arrow.json'
import Eye from '../assets/animations/eye.json'
import Lines from '../assets/animations/lines.json'
import Shapes from '../assets/animations/shapes.json'
import WarmUp from '../assets/images/warm-up.inline.svg'
import Testimonial from '../components/testimonial/testimonial'
import TextImageSection from '../components/textImageSection/textImageSection'

type AnimationName = string
type LottieFile = any
type SVGImage = any

type AnimationObject =
  | {
      desktop: LottieFile
      mobile: LottieFile
    }
  | SVGImage

type AnimationMap = Record<AnimationName, AnimationObject>

const animationMap: AnimationMap = {
  ideate: Lines,
  observe: Eye,
  strategize: Arrow,
  validate: Shapes,
  'warm-up': WarmUp,
}

const Home: React.FC<PageProps<
  Queries.HomePageQuery,
  Queries.HomePageQueryVariables
>> = ({ data }) => {
  if (!data.directus.home) {
    return null
  }

  const {
    featured,
    highlight,
    latest,
    picture,
    popular,
    source,
    text,
  } = data.directus.home
  const HighlightImage = animationMap[highlight?.slug ?? 0]

  return (
    <DefaultLayout hasBanner>
      <Banner />
      {featured && (
        <Section className="featured-tools-section">
          <div className="horizontal-cards-section">
            <HorizontalCardsGrid>
              {featured.map(category => {
                if (!category?.category_id || !category.category_id.slug) {
                  return null
                }

                const {
                  slug,
                  headline,
                  description,
                  name,
                } = category.category_id

                return (
                  <HorizontalCard
                    animation={animationMap[slug]}
                    animationClassName={`horizontal-card__image--${slug}`}
                    headline={headline}
                    key={slug}
                    link={`/categories/${slug}/`}
                    linkLabel={`Explore ${name} Methods`}
                    teaser={description}
                  />
                )
              })}
            </HorizontalCardsGrid>
          </div>
        </Section>
      )}
      {highlight && highlight.headline && (
        <Section className="home__highlight">
          <TextImageSection
            imageContent={
              <div className="home__highlight__image">
                <HighlightImage />
              </div>
            }
            headline={highlight.headline}
          >
            {highlight.description && (
              <div
                dangerouslySetInnerHTML={{ __html: highlight.description }}
              />
            )}
            <Button
              href={`/categories/${highlight.slug}/`}
              tag="a"
              tabIndex="-1"
              className="home__highlight__button"
            >
              Explore {highlight.name} Methods
            </Button>
          </TextImageSection>
        </Section>
      )}
      {popular?.methods && popular.methods.length > 0 && (
        <Section className="featured-tools-section">
          <header className="section-header section-header--flex">
            <SectionHeadline className="section-header__headline">
              {popular.name}
            </SectionHeadline>
          </header>
          <ToolTileGrid className="templates__grid">
            {popular.methods.map(methodItem => {
              if (!methodItem?.method_id) {
                return null
              }

              const method: Method = mapMethod(methodItem.method_id)

              return (
                <ToolTile
                  category={method.categories[0]}
                  duration={method.duration}
                  iconUrl={method.thumb}
                  key={method.slug}
                  link={`/methods/${method.slug}/`}
                  participants={method.participants}
                  summary={method.headline}
                  tags={method.tags}
                  title={method.name}
                />
              )
            })}
          </ToolTileGrid>
        </Section>
      )}
      {latest?.methods && (
        <Section className="featured-tools-section" key={latest.name}>
          <header className="section-header section-header--flex">
            <SectionHeadline className="section-header__headline">
              {latest.name}
            </SectionHeadline>
          </header>
          <ToolTileGrid className="templates__grid">
            {latest.methods.map(methodItem => {
              if (!methodItem?.method_id) {
                return null
              }

              const method: Method = mapMethod(methodItem.method_id)

              return (
                <ToolTile
                  category={method.categories[0]}
                  duration={method.duration}
                  iconUrl={method.thumb}
                  key={method.slug}
                  link={`/methods/${method.slug}/`}
                  participants={method.participants}
                  summary={method.headline}
                  tags={method.tags}
                  title={method.name}
                />
              )
            })}
          </ToolTileGrid>
        </Section>
      )}
      {picture?.imageFile?.publicURL && source && text && (
        <Section className="home__quote">
          <Testimonial picture={picture.imageFile?.publicURL} source={source}>
            {text}
          </Testimonial>
        </Section>
      )}
    </DefaultLayout>
  )
}

export default Home

export const query = graphql`
  query HomePage {
    directus {
      home {
        featured {
          category_id {
            slug
            headline
            name
            description
          }
        }
        highlight {
          slug
          headline
          name
          description
        }
        popular {
          name
          methods {
            method_id {
              ...methodFragment
            }
          }
        }
        latest {
          name
          methods {
            method_id {
              ...methodFragment
            }
          }
        }
        source
        text
        picture {
          ...imageFragment
        }
      }
    }
  }

  fragment methodFragment on DirectusData_method {
    name
    logo {
      ...imageFragment
    }
    headline
    categories {
      category_id {
        slug
      }
    }
    slug
    tags
    participants_from
    participants_to
    duration_from
    duration_to
    gallery {
      directus_files_id {
        ...imageFragment
      }
    }
    difficulty
  }

  fragment imageFragment on DirectusData_directus_files {
    id
    imageFile {
      publicURL
    }
  }
`
