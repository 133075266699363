import React from 'react'
import './textImageSection.sass'

type Props = {
  children: React.ReactNode
  headline: string
  imageContent: React.ReactNode
} & Pick<React.HTMLAttributes<HTMLElement>, 'className'>

const TextImageSection: React.FC<Props> = ({
  children,
  className,
  headline,
  imageContent,
}) => (
  <section className={`text-image-section${className ? ` ${className}` : ''}`}>
    <div className="text-image-section__content text-image-section__content--image">
      {imageContent}
    </div>
    <div className="text-image-section__content text-image-section__content--text">
      <h2 className="h2 h--decor text-image-section__headline">{headline}</h2>
      {children}
    </div>
  </section>
)

export default TextImageSection
